<template>
  <div>
    <CRow>
      <CCol sm="12">
        <h4 class="text-uppercase">Water Vehicle Requests</h4>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="6"> </CCol>
            <CCol sm="2">
              <div class="form-group">
                <select
                  name="status"
                  v-model="search.status"
                  class="form-control"
                  @change="getWaterVehicleRequests(search)"
                  required
                >
                  <option value="">Select Status</option>
                  <!-- <option value="PENDING">Pending</option> -->
                  <option value="CONFIRMED">Delivery Pending</option>
                  <!-- <option value="PROCESSING">Processing</option> -->
                  <option value="COMPLETED">Delivered</option>
                </select>
              </div>
            </CCol>
            <CCol sm="2">
              <CButton
                type="button"
                class="w-100"
                color="success"
                @click="getWaterVehicleRequests(search)"
                >Search</CButton
              >
            </CCol>
          </CRow>

          <CRow>
            <div class="table-responsive">
              <table class="table table-borderless">
                <thead>
                  <tr class="text-center">
                    <th class="font-weight-bold">SL</th>
                    <th class="font-weight-bold">Consumer Name</th>
                    <th class="font-weight-bold">Water Volume</th>
                    <th class="font-weight-bold">Address</th>
                    <th class="font-weight-bold">Amount (BDT)</th>
                    <th class="font-weight-bold">Payment Method</th>
                    <th class="font-weight-bold">Status</th>
                    <th class="font-weight-bold">Created At</th>
                    <th
                      class="font-weight-bold"
                      v-if="checkUsrPermissions(['WV_all', 'WV_show'])"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody v-if="waterVehicleRequests.length">
                  <tr
                    class="text-center"
                    v-for="(waterVehicleRequest, index) in waterVehicleRequests"
                    :key="waterVehicleRequest.id"
                  >
                    <td scope="row">{{ meta.from + index }}</td>
                    <td>{{ waterVehicleRequest.customer_name }}</td>
                    <td>
                      {{ waterVehicleRequest.water_volume }}
                      {{ waterVehicleRequest.water_unit }}
                    </td>
                    <td>{{ waterVehicleRequest.address }}</td>
                    <td>{{ waterVehicleRequest.total_amount }}</td>
                    <td>{{ waterVehicleRequest.payment_method }}</td>
                    <td>
                      <span
                        class="badge status-badge p-1"
                        @click="markAsDelivered(waterVehicleRequest)"
                        :title="
                          waterVehicleRequest.status === 'CONFIRMED'
                            ? 'Click here to mark as delivered.'
                            : ''
                        "
                        :class="
                          waterVehicleRequest.status === 'CONFIRMED'
                            ? 'badge-primary'
                            : 'badge-success'
                        "
                        >{{ reStatus[waterVehicleRequest.status] }}
                      </span>
                    </td>
                    <td>{{ waterVehicleRequest.created_at | moment }}</td>
                    <td v-if="checkUsrPermissions(['WV_all', 'WV_show'])">
                      <router-link
                        v-if="checkUsrPermissions(['WV_all', 'WV_show'])"
                        :to="
                          '/water-vehicle-requests/' + waterVehicleRequest.id
                        "
                        class="btn btn-info btn-sm"
                        >View</router-link
                      >
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="8">
                      <h5 class="text-center">
                        Water Vehicle Requests Not Available.
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CRow>
          <CPagination
            v-if="waterVehicleRequests.length"
            align="center"
            :pages="rows"
            :active-page.sync="search.currentPage"
            @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "WaterVehicleRequests",
  data: () => {
    return {
      search: {
        status: "",
        currentPage: 1,
      },
      reStatus: { CONFIRMED: "Delivery Pending", COMPLETED: "Delivered" },
      deletingState: false,
      loadingState: false,
      role: {},
      serverErrors: [],
      showDismissibleAlert: false,
      months: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
      // isLoading: false,
    };
  },
  components: {},
  filters: {
    moment: function(date) {
      return moment(date).format("DD MMM, YYYY HH:mm:ss");
    },
  },
  methods: {
    markAsDelivered(request) {
      try {
        this.$store.dispatch("WaterVehicleRequest/markAsDelivered", request);
      } catch (err) {
        console.log(err);
      }
    },

    handlePageChange(value) {
      this.search.currentPage = value;
      this.$router.replace({
        name: "Water Vehicle Requests",
        query: this.search,
      });
      this.$store.dispatch(
        "WaterVehicleRequest/getWaterVehicleRequests",
        this.search
      );
    },
    getWaterVehicleRequests(search) {
      search.currentPage = 1;
      this.$router.replace({
        name: "Water Vehicle Requests",
        query: this.search,
      });
      this.$store.dispatch(
        "WaterVehicleRequest/getWaterVehicleRequests",
        search
      );
    },
    setSearchParams() {
      this.search.status = this.$route.query.status
        ? this.$route.query.status
        : "";
      this.search.currentPage = this.$route.query.currentPage
        ? parseInt(this.$route.query.currentPage)
        : 1;
    },
  },
  mounted() {
    this.setSearchParams();
    this.$store.dispatch("WaterVehicleRequest/getWaterVehicleRequests", this.search);
  },

  computed: {
    ...mapGetters("WaterVehicleRequest", [
      "waterVehicleRequests",
      "rows",
      "perPage",
       "meta"
    ]),
  },
};
</script>

<style scoped>
.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
